import React from 'react';
import { MDXRenderer } from "gatsby-plugin-mdx"
import {graphql, Link} from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components2/Layout";
import GoodReads from '../components2/GoodReads';
import RecommendedReads from '../components2/RecommendedReads';
import Seo from '../components2/Seo';
import useCheckMobileScreen from '../components2/utils/checkMobileScreen';
import { FAQJsonLd } from 'gatsby-plugin-next-seo';

import palleteSVG from '../../static/img/pallete.svg'
import chakraSVG from '../../static/img/chakra.svg'
import chineseSVG from '../../static/img/chinese.svg'
import productionSVG from '../../static/img/production.svg'
import rareSVG from '../../static/img/rare.svg'
import textureSVG from '../../static/img/texture.svg'
import foundSVG from '../../static/img/found.svg'

import Toc from '../components2/toc/toc';
import TocMobile from '../components2/toc/toc-mobile';

import styled from "styled-components"

const MDXWrapper = styled.div`
@media screen and (max-width: 670px){
  order:3;
}

@media screen and (min-width: 1024px){
  padding-left: 5rem;
}

`


const FeaturedImageWrapperArticle = styled.div`
max-height:500px;
order:2;
`


const ContainerArticle = styled.div`
@media screen and (min-width: 1024px){
  grid-column: 1/12;
}
@media screen and (max-width: 670px){
  margin-top:5px; 
  order:1;
  justify-content: space-around;
  align-items: center;
}
  width: 100%;
  display:flex;
  flex-direction:column;
  text-align:left;
  grid-column: 1/12;
`
const TopLine = styled.div`
@media screen and (max-width: 670px){
width:100%;
justify-content: space-around;
flex-wrap: wrap;
}
display:flex;
flex-direction:row;
align-items:center;
`

const HeaderContainer = styled.div`
margin-bottom: 1.5rem;
`

const BannerSubtitle = styled(Link)`
@media screen and (max-width: 670px){
    font-size: 1rem;
}
text-transform: capitalize;
font-size: 1rem;
color: black;
text-decoration: none;
`

const BannerTagArticle = styled(Link)`
@media screen and (max-width: 670px){
    font-size: 80%;
    margin-right: 0;
    min-width: 50%;
    text-align:center;
}
text-transform: capitalize;
font-size: 1rem;
color: black;
text-decoration: none;
margin-right: 1rem;
`

const BannerAuthorArticle = styled(Link)`
@media screen and (max-width: 670px){
  font-size: 80%;
  margin-right: 0;
  min-width: 50%;
  text-align:center;
}
text-transform: capitalize;
font-size: 1rem;
color: black;
text-decoration: none;
margin-right: 1rem;
`

const BannerTitle = styled.h1`
@media screen and (min-width: 671px){
    margin-top: 15px;
    margin-bottom: 15px;
}

@media screen and (max-width: 1024px){
    font-size: 2.25rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
color: #C24C92;
font-size: calc(1.375rem + 1.5vw); 
`

const BannerTitleArticle = styled.h1`
@media screen and (min-width: 671px){
    margin-top: 16px;
    padding-bottom: 8px;
    text-align:left;
}

@media screen and (max-width: 1024px){
    font-size: 2.25rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

color: #C24C92;
font-size: calc(1.375rem + 1.5vw); 
`

const BannerDateArticle =styled.div`
margin-right: 1rem;
color: black;
@media screen and (max-width: 670px){
  font-size: 80%;
  margin-right: 0;
  min-width: 50%;
  text-align:center;
  margin-top: 10px;
}
`
const BannerSubtitleArticle = styled.div`
@media screen and (max-width: 670px){
  font-size: 1rem;
  width:90vw;
}
text-transform: capitalize;
font-size: 1rem;
color: black;
text-decoration: none;
font-weight:700;
margin-bottom: 16px;
width:60vw;
`
const GridWrap = styled.div`
@media screen and (min-width: 1024px){
    grid-template-columns: 1fr minmax(0,40ch) minmax(0,2rem) 71ch 2rem 30ch 1fr;
    width: 100%;
}

@media screen and (max-width: 1024px){
  grid-template-columns: 1fr minmax(0, 95ch) 1fr;
}
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: 1fr minmax(0, 70ch) 1fr;
    margin-bottom: 4rem;
}


`

const GridWrapArticle = styled.div`
@media screen and (min-width: 1024px){
  grid-template-columns: repeat(12, 1fr);
}

@media screen and (max-width: 1024px){
}
grid-auto-flow: dense;
display: grid;
width: 100%;
}
`

const BlogLayout = styled.div`
box-sizing: border-box;
margin: 0;
min-width: 0;
margin: 0 auto;
width: 90vw;
display: flex;
flex-direction: column;
align-items: center;
`

const BlogLayoutArticle = styled.div`
box-sizing: border-box;
margin: 0;
min-width: 0;
margin: 0 auto;
display: flex;
flex-direction: column;
align-items: center;
`

const SideBar = styled.div`
@media screen and (min-width: 1024px){
    grid-column: 6/7;
}

    grid-column: 2/3;
`

const SideBarArticle = styled.div`
@media screen and (min-width: 1024px){
  min-width:300px;
  grid-column: 11/12;
  margin-top: -75px;
  margin-right: -5vw;
}
grid-column: 1/12;
`

const MainPost = styled.article`
@media screen and (min-width: 1024px){
    grid-column: 2/5;
}

    grid-column: 2/3;
`

const MainPostArticle = styled.article`
@media screen and (min-width: 671px){
  padding-left: 3rem;
  padding-right: 3rem;
  grid-column: 1/11;
  max-width: 870px;
}
@media screen and (min-width: 1441px){
  grid-column: 5/11;
}
@media screen and (max-width: 670px){
  display:flex;
  flex-direction:column;
  padding: 0 5vw;
}
grid-template-columns: repeat(8, 1fr) minmax(300px, 4fr);
grid-column: 1/12;
`

const TopInfoRow = styled.div`
display:flex;
flex-wrap: wrap;
`

const TopInfoRowLeft = styled.div`
flex: 0 0 auto;
width: 100%;
margin-bottom: 1.5rem;
@media screen and (min-width: 1024px){
  width: 50%;
  margin-bottom: 0;
}
`

const TopInfoRowRight = styled.div`
flex: 0 0 auto;
width: 100%;
@media screen and (min-width: 1024px){
  width: 50%;
  padding-left: 1.5rem !important;
}

`

const Statrow = styled.li`
display: flex;
flex-direction:row;
margin-bottom: 1rem !important;
align-items: center;
`
const StatrowText = styled.p`
margin-left: 1rem;
`

const GatsbyFeaturedImage = styled(GatsbyImage)`

@media screen and (min-width: 671px){
  max-height:450px; 
  min-height:100%;
}
`

const Snippet = styled.p`
line-height: 1.5;
margin-bottom: 1rem;
font-size: 1.25rem;
`
const QuickLook = styled.h4`
margin-top: 1rem;
margin-bottom: 1rem;
padding:0;`

const TinySVG = styled.img`
height: 30px;
width: 30px;
`



const Post = ({data}) => {
    console.log(data)
    const { frontmatter, tableOfContents } = data.post
    const seoUrl = data.post.fields.slug.replace(/\s+/g, '-').toLowerCase();
    const result = data.post;
    const embeddedImagesLocal = result.frontmatter.embeddedImagesLocal
    const isDesktop = useCheckMobileScreen()
    const titleID = frontmatter.title.replace(/\s+/g, '-').toLowerCase();
    const tempTagUrl = frontmatter.tags[0].replace(/\s+/g, '-');
    if (!data) return null;
    return (
      <Layout>
        <Seo title={frontmatter.title} description={frontmatter.metaDescription} keywords={frontmatter.keywords} defer={false}>
       { frontmatter.FAQReady && <script type="application/ld+json">{
    `{
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "name":"FAQ Section",
      "mainEntity": [{
        "@type": "Question",
        "name": "${frontmatter.question1}",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>${frontmatter.answer1}</p>"
        }
      }, {
        "@type": "Question",
        "name": "${frontmatter.question2}",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "${frontmatter.answer2}"
        }
      }]
    }`}
    </script> }
    <script type="application/ld+json">{
    `{
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "${frontmatter.tags[0]}",
        "item": "https://www.charmedcrystal.com/${tempTagUrl}"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "${result.frontmatter.title}",
        "item": "https://www.charmedcrystal.com${seoUrl}"
      }
    ]
    }`}
    </script> 
        </Seo>
        {frontmatter.article === false && <BlogLayout>
          <GridWrap>
             <SideBar>
              {
                  typeof tableOfContents.items === 'undefined' ? null :  typeof window !== 'undefined' 
                  ? window.innerWidth <= 670 ? <TocMobile tableOfContents={tableOfContents} isDesktop={isDesktop} /> : <Toc tableOfContents={tableOfContents} isDesktop={isDesktop} /> 
                  : null
              }
            </SideBar>
            
          <MainPost>
          <TopInfoRow>
           <TopInfoRowLeft> { result.frontmatter.featuredimage &&
                <GatsbyFeaturedImage
                  image={result.frontmatter.featuredimage.childImageSharp.gatsbyImageData}
                  alt= {`featured image thumbnail for ${result.frontmatter.title}`}
                  height="100%"
                  imgStyle={{ objectFit: 'contain' }} />
                }
              </TopInfoRowLeft>
              <TopInfoRowRight>
              <div>
                <HeaderContainer>
                    <div>
                      <nav>
                          <p><BannerSubtitle to="/">Home</BannerSubtitle> &raquo; <BannerSubtitle to={`/${tempTagUrl}`}>{frontmatter.tags[0]}</BannerSubtitle> &raquo;</p>
                      </nav>
                    </div>
                    <BannerTitle id={titleID}>{frontmatter.title}</BannerTitle>
                    <div> Updated: <time datetime="2022-03-29T10:05:36-07:00">{frontmatter.date}</time> </div>
                </HeaderContainer>
                <div>
                    <Snippet>{frontmatter.metaDescription}</Snippet>
                    <section>
                      <QuickLook>Quick Look:</QuickLook>
                      <ul>
                          <Statrow>
                          <TinySVG src={chakraSVG} alt="Incredible Art"></TinySVG>
                          <StatrowText>{frontmatter.chakra} Chakra</StatrowText>
                          </Statrow>
                          <Statrow>
                          <TinySVG src={palleteSVG} alt="Incredible Art"></TinySVG>
                          <StatrowText>{frontmatter.colors}</StatrowText>
                          </Statrow>
                          <Statrow>
                          <TinySVG src={textureSVG} alt="Incredible Art"></TinySVG>
                          <StatrowText>{frontmatter.texture}</StatrowText>
                          </Statrow>
                          <Statrow>
                          <TinySVG src={rareSVG} alt="Incredible Art"></TinySVG>
                          <StatrowText>{frontmatter.cost} / 10 Rarity</StatrowText>
                          </Statrow>
                          <Statrow>
                          <TinySVG src={foundSVG} alt="Incredible Art"></TinySVG>
                          <StatrowText>Discovered in {frontmatter.origin}</StatrowText>
                          </Statrow>
                          <Statrow>
                          <TinySVG src={productionSVG} alt="Incredible Art"></TinySVG>
                          <StatrowText>Mined in {frontmatter.producers}</StatrowText>
                          </Statrow>
                          <Statrow>
                          <TinySVG src={chineseSVG} alt="Incredible Art"></TinySVG>
                          <StatrowText>Chinese name: {frontmatter.chineseName}</StatrowText>
                          </Statrow>
                      </ul>
                    </section>
                </div>
               </div>
              </TopInfoRowRight>
</TopInfoRow>

            <MDXRenderer localImages={embeddedImagesLocal}>{result.body}</MDXRenderer>
            </MainPost>
          </GridWrap>
          <GoodReads data={data.goodReads}/>
        </BlogLayout>
}
{frontmatter.article === true && 
        <BlogLayoutArticle>

      <GridWrapArticle>
            <SideBarArticle>
              {
                  typeof tableOfContents.items === 'undefined' ? null :  typeof window !== 'undefined' 
                  ? window.innerWidth <= 670 ? <TocMobile tableOfContents={tableOfContents} isDesktop={isDesktop} /> : <Toc tableOfContents={tableOfContents} isDesktop={isDesktop} /> 
                  : null
              }
            </SideBarArticle>
            <MainPostArticle>
            <ContainerArticle>
                    <TopLine>
                        <BannerAuthorArticle><span>Matthew Bryan</span></BannerAuthorArticle>
                        <BannerTagArticle to={`/${tempTagUrl}`}> {frontmatter.tags[0]}</BannerTagArticle>
                        <BannerDateArticle><span>{frontmatter.date}</span></BannerDateArticle>
                    </TopLine>
                    <BannerTitleArticle id={titleID}>{frontmatter.title}</BannerTitleArticle>
            </ContainerArticle>
            <FeaturedImageWrapperArticle>
            <GatsbyImage
                  image={result.frontmatter.featuredimage.childImageSharp.gatsbyImageData}
                  alt= {`featured image thumbnail for post ${result.frontmatter.title}`}
                  style={{ maxHeight: '500px' }}
                  imgStyle={{ objectFit: 'contain' }} />
            </FeaturedImageWrapperArticle>
            <MDXWrapper>
            <MDXRenderer localImages={embeddedImagesLocal}>{result.body}</MDXRenderer>
            </MDXWrapper>
          </MainPostArticle>
          </GridWrapArticle>
          <RecommendedReads data={data.relatedMdxs}/>
        </BlogLayoutArticle>
}
      </Layout>
    );
}


export default Post

export const pageQuery = graphql`
  query postByID($id: String!) {
    relatedMdxs(parent: { id: { eq: $id } }) {
      posts {
        frontmatter {
          title
          tags
          article
          featuredimage {
            childImageSharp {
              large: 
                  gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  layout: FULL_WIDTH
                  sizes: "(max-width: 1000px) 400px, 50vw"
                )
              small: 
                  gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  layout: FULL_WIDTH
                  sizes: "(max-width: 1000px) 200px, 25vw"
                )
              }
          }
        }
        slug
      }
    }
    post:  mdx(id: { eq: $id }) {
      fields{
        slug
      }
      id
      body
      tableOfContents
      frontmatter {
        question1
        question2
        answer1
        answer2
        title
        chakra
        hardness
        chineseName
        texture
        origin
        producers
        cost
        article
        colors
        date(formatString: "MMMM DD, YYYY")
        metaDescription
        tags
        featuredimage {
          childImageSharp {
            gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
          }
        }
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
          }
        }
      }
    }
    goodReads:  allMdx(filter: {frontmatter: {templateKey: {eq: "blog-post"}}}) {
        edges {
          node {
            id
            slug
            frontmatter {
              title
              tags
              featuredimage {
                childImageSharp {
                  large: 
                      gatsbyImageData(
                      placeholder: DOMINANT_COLOR
                      layout: FULL_WIDTH
                      sizes: "(max-width: 1000px) 400px, 50vw"
                    )
                  small: 
                      gatsbyImageData(
                      placeholder: DOMINANT_COLOR
                      layout: FULL_WIDTH
                      sizes: "(max-width: 1000px) 200px, 25vw"
                    )
                  }
              }
            }
          }
        }
      }
  }
`